.sv-sharepoint-error {
   display: table-row;
}

.sv-sharepoint-error div {
   display: table-cell;
   padding-left: 5px;
   vertical-align: top;
}

.sv-sharepointsearchfilelist-portlet ul,
.sv-sharepointfile-portlet ul {
   left: auto;
   right: 0;
}

.sv-sharepointfile-portlet ul li a {
   display: flex;
}