.sv-file-portlet .sv-file-portlet-fileicon {
  width: 22px;
}

.sv-file-portlet .sv-file-portlet-fileicon img {
  display: block;
}

.sv-file-portlet .sv-file-portlet-file {
  width: 100%;
}

.sv-file-portlet .sv-file-portlet-right {
  white-space: nowrap;
  text-align: right;
}

.sv-file-portlet ul {
  display: table;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sv-file-portlet tbody {
  word-break: break-all;
}

.sv-file-portlet table,
.sv-file-portlet ul {
  width: 100%;
  border: none;
  border-collapse: collapse;
}

.sv-file-portlet li {
  display: table-row;
}

.sv-file-portlet ul li div {
  display: table-cell;
}

.sv-file-portlet th,
.sv-file-portlet td,
.sv-file-portlet li div {
  vertical-align: middle;
  padding: 2px 5px;
}

.sv-file-portlet th:first-child,
.sv-file-portlet td:first-child,
.sv-file-portlet li div:first-child {
  padding-left: 1px;
}

.sv-file-portlet th:last-child,
.sv-file-portlet td:last-child,
.sv-file-portlet li div:last-child {
  padding-right: 1px;
}
