.sv-comments2-portlet .sv-comments2-list {
   margin:0;
   padding:0;
   list-style:none
}

.sv-comments2-portlet .sv-comments2-list li.sv-comment2-comment {
   border-bottom:1px solid #EEE;
   padding:1em;
   margin:0;
   position:relative
}

.sv-comments2-portlet .sv-comments2-list li.sv-comment2-comment:hover {
   background-color:whiteSmoke
}
.sv-comments2-portlet .sv-comments2-list li.sv-comment2-comment.sv-comment-hidden:after {
   content:'';
   background-color:grey;
   height:100%;
   width:100%;
   position:absolute;
   top:0;
   left:0;
   opacity:0.3
}

.sv-comments2-portlet .sv-input-section {
   padding:1em
}

.sv-comments2-portlet form.sv-comments2-form {
   overflow:hidden
}

.sv-comments2-portlet .sv-comments2-input {
   width:100%;
}

.sv-comments2-portlet .sv-comments2-content {
   margin: 3px 0 5px 0
}

.sv-comments2-portlet .sv-comments2-comment {
   position: relative
}
.sv-comments2-portlet .sv-comments2-header {
   margin:0;
   display:inline
}
.sv-comments2-portlet .sv-comments2-header small{
   color: #999;
   font-weight: normal;
   font-size:70%
}
.sv-comments2-portlet .sv-buddy-icon {
   margin-right:5px
}

.sv-comments2-portlet .sv-meta-info {
   list-style:none;
   padding:0;
   -webkit-box-sizing:border-box;
   -moz-box-sizing:border-box;
   box-sizing:border-box
}

.sv-comments2-portlet .sv-meta-info a {
   text-decoration:none;
   cursor:pointer
}

.sv-comments2-portlet .sv-meta-info i {
   margin-left:3px;
   margin-right:2px
}

.sv-comments2-portlet .sv-meta-info i:first-child {
   margin-left:0px
}

.sv-comments2-portlet .sv-meta-info li {
   float:left;
   margin-left:2.5em
}

.sv-comments2-portlet .sv-meta-info li:first-child {
   margin-left:0
}

.sv-comments2-portlet .halflings-icon {
   vertical-align: middle
}

.sv-comments2-portlet .sv-comments2-container {
   margin-left: 40px;
   position: relative;
   word-wrap: break-word;
}

.sv-comments2-portlet .sv-comments2-container.sv-no-img {
   margin-left: 0px;
}

.sv-comments2-portlet .sv-cog {
   position:absolute;
   top:5px;
   right:5px;
   text-decoration:none;
   z-index:1
}

.sv-comments2-portlet .sv-cog.open {
   z-index:2
}

.sv-comments2-portlet .sv-comments2-main-header .sv-cog {
   position:absolute;
   top:5px;
   right:5px;
   text-decoration:none;
   z-index:2
}

.sv-comments2-portlet .sv-comments2-main-header {
   position:relative;
   border-bottom: 1px solid #EEEEEE;
   padding-bottom:10px
}

.sv-comments2-portlet .sv-comments2-main-dropdown {
   z-index:2
}

.sv-comments2-portlet .sv-comments2-hidden-comment-text {
   display:inline
}

.sv-comments2-portlet .sv-comment-input {
   width:100%
}
.sv-comments2-portlet .frc-captcha {
   margin: 0 0.5em 0.5em 0;
}

.sv-character-limit-exceeded {
   color:red;
}

.sv-help-block-verify {
   display:none;
   color:red;
}