.sv-sharepointsearchfile-files .halflings-icon.lock {
   opacity: .6;
   vertical-align: middle;
}

.sv-sharepointsearchfile-files .menu-label {
   color: #858585;
}

.sv-sharepointsearchfile-files button {
   float: right;
}

.sv-sharepointsearchfile-files .env-dropdown__menu a {
   text-decoration: none;
}